table,
th,
tr,
td {
  padding: 0.3em;
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.center-div {
  width: 100%;
}

.center {
  width: 100%;
  margin: 1em auto;

}

.box {
  box-shadow: 0px 2px 4px black;
  width: 60%;
  margin: 1em auto;
}

input[type='text'] {
  padding: 0.5em;
  flex-grow: 1;
}

.description {
  text-align: start;
}

.btn {
  padding: 1em;
  color: white;
  background-color: blueviolet;
  border: 1px transparent;
  border-radius: 0.2em;
  margin: 1em;
  width: 7em;
}

.todo_input{
  display: flex;
  justify-content: center;
  margin: 1rem 5rem;
  gap: 1em;
}

.todo_input > input{
  max-width:20rem;
}

@media screen and (max-width:600px){
  .box{
    width: 90%;
    height: 50vh;
  }

}